import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps" //npm install --save react-google-maps
//https://tomchentw.github.io/react-google-maps/
import Swal from 'sweetalert2'; //npm install --save sweetalert2
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)

//const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBjoimOQOcqD_5K2JwOR-3d3WlDguKmxmM&v=3.exp&libraries=geometry,drawing,places";
const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCe3gBSL4p1HctLMiVgGw1aYEcs2BKJYpw&v=3.exp&libraries=geometry,drawing,places";
const loadingElement = <div style={{ height: `100%` }} />;
const containerElement = <div style={{ height: `99%` }} />;
const mapElement = <div style={{ height: `100%` }} />;
var userDefaultLatitude = 0;
var userDefaultLongitude = 0;

export const vehicleData = [
    {
        rideId: 4422521535
        , vehicleId: 5
        , vehicleNo: "MH05 EP 3700"
        , vehicleTypeId: 5
        , vehicleType: "VAN"
        , latitude: 40.731887783696465
        , longitude: - 73.86869420143684
        , gpsDateTimeStr: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        , rideStatusId: 107
        , gpsstatus: "ON LOCATION"
        , statusId: 303
        , statusDescription: "ON JOB"
        , pickupDateTimeStr: "03/12/2021 13:26"
        , driverName: "Dharmendra"
        , mobileNo: "8652197699"
        , puStreet: "JFK Airport Terminal 4 NY 2001"
        , doStreet: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , puLatitude: 40.644418
        , puLongitude: - 73.789061
        , doLatitude: 40.70871557720287
        , doLongitude: - 73.99368082930151
        , speed: 20
        , pax: 2
    },
    {
        rideId: 4422521535
        , vehicleId: 5
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , vehicleType: "VAN"
        , latitude: 40.907610229562756
        , longitude: - 72.8262761242683
        , gpsDateTimeStr: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        , rideStatusId: 107
        , gpsstatus: "ON LOCATION"
        , statusId: 303
        , statusDescription: "ON JOB"
        , pickupDateTimeStr: "03/12/2021 13:26"
        , driverName: "Dharmendra"
        , mobileNo: "8652197699"
        , puStreet: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doStreet: "JFK Airport"
        , puLatitude: 40.644418
        , puLongitude: - 73.789061
        , doLatitude: 40.70871557720287
        , doLongitude: - 73.99368082930151
        , speed: 20
        , pax: 3
    },
    {
        rideId: 4422521535
        , vehicleId: 5
        , vehicleNo: "MH05 EP 3702"
        , vehicleTypeId: 5
        , vehicleType: "VAN"
        , latitude: 40.81193162529278
        , longitude: - 74.29548149014192
        , gpsDateTimeStr: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        , rideStatusId: 107
        , gpsstatus: "ON LOCATION"
        , statusId: 303
        , statusDescription: "ON JOB"
        , pickupDateTimeStr: "03/12/2021 13:26"
        , driverName: "Dharmendra"
        , mobileNo: "8652197699"
        , puStreet: "JFK Airport"
        , doStreet: "Manhantton Hotel"
        , puLatitude: 40.644418
        , puLongitude: - 73.789061
        , doLatitude: 40.70871557720287
        , doLongitude: - 73.99368082930151
        , speed: 20
        , pax: 5
    },

]

export class TrackingComponent extends Component {
    static displayName = TrackingComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            rideId: 0,
            trackingData: [],
            userDefaultLatitude: 0,
            userDefaultLongitude: 0,
            errors: {
                userName: '',
            }
        }
        this.getUserLocationData = this.getUserLocationData.bind(this);
        this.getTrackingData = this.getTrackingData.bind(this);
        this.refreshMap = this.refreshMap.bind(this);
        this.multipleVehicleMap = React.createRef();
    }

    async componentDidMount() {
        await this.getTrackingData();
    }

    async refreshMap() {
        window.location.reload(false);
    }

    async getUserLocationData() {
        var accountId = 0, userId = 0;
        try {
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);

            if (parsed) {
                var request = new Object();
                accountId = parseInt(parsed.accountId);
                userId = parseInt(parsed.userId);

                const requestParams = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }

                const response = await fetch('tracking-api/user-default-location/' + userId + '/' + accountId, requestParams);
                const data = await response.json();
                debugger;


                if (data.userDefaultLocations !== null) {
                    userDefaultLatitude = data.userDefaultLocations[0].userDefaultLatitude;
                    userDefaultLongitude = data.userDefaultLocations[0].userDefaultLongitude;
                }

                //console.log("userDefaultLatitude: " + userDefaultLatitude);
                //console.log("userDefaultLongitude: " + userDefaultLongitude);

                //this.setState({
                //    userDefaultLatitude: data.userDefaultLocations === null ? 0 : data.userDefaultLocations[0].userDefaultLatitude,
                //    userDefaultLongitude: data.userDefaultLocations === null ? 0 : data.userDefaultLocations[0].userDefaultLongitude
                //});
            } else {
                userDefaultLatitude = 0;
                userDefaultLongitude = 0;

                //this.setState({
                //    userDefaultLatitude: 0,
                //    userDefaultLongitude: 0
                //});
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getTrackingData() {
        try {
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            
            if (parsed) {
                this.setState({ rideId: parseInt(parsed.rideId) })
                var request = new Object();
                request.AccountId = parseInt(parsed.accountId);
                request.LocationId = parseInt(parsed.locationId);
                request.RideId = parseInt(parsed.rideId);
                request.VgTypeId = parsed.vgTypeId != undefined ? parsed.vgTypeId : 0;
                request.LoginUserId = parsed.userId != undefined ? parseInt(parsed.userId) : 0;

                const requestParams = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(request)
                }
                let vehicleId = 0;
                const response = await fetch('tracking-api/' + vehicleId + '/track-list', requestParams);
                const data = await response.json();

                let trackingData = data.trackVehicles == null ? [] : data.trackVehicles;
                this.setState({
                    trackingData: trackingData
                });

                if (trackingData.length <= 0) {
                    const isValid = await Swal.fire({
                        title: "<small>Locate Vehicle</small>",
                        text: "Unable to find GPS location of vehicle.",
                        icon: "success",
                        width: '45%',
                        dangerMode: false,
                        allowOutsideClick: false
                    });
                }
                //this.setState({
                //    trackingData: vehicleData,
                //    rideId: parseInt(0)
                //});
            } else {
                this.setState({
                    trackingData: []
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div className="map-container">
                {this.state?.trackingData?.length == 0 && <DefaultVehicleMap refreshMap={this.refreshMap} />}
                {(this.state?.trackingData?.length > 0 && this.state.rideId > 0) && <SingleVehicleMap vehicleDetails={this.state.trackingData} refreshMap={this.refreshMap} />}
                {(this.state?.trackingData?.length > 0 && this.state.rideId == 0) && <MultipleVehicleMap vehicleDetails={this.state.trackingData} refreshMap={this.refreshMap} />}
            </div>

        );
    }
}

const DefaultVehicleMap = compose(
    withProps({
        googleMapURL: googleMapURL,
        loadingElement: loadingElement,
        containerElement: containerElement,
        mapElement: mapElement,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const refs = {}
            this.setState({

            });
        }
    })
)(props =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: userDefaultLatitude, lng: userDefaultLongitude }}
        defaultOptions={{
            mapTypeControl: true,
            streetViewControl: true,
            zoomControl: true,
            fullscreenControl: false,
            minZoom: 2,
            maxZoom: 15,
            gestureHandling: "greedy",
            disableDefaultUI: true
        }}
    >
        {props.isMarkerShown && <Marker position={{ lat: this.state.userDefaultLatitude, lng: this.state.userDefaultLongitude }} />}
        <div><button type="button" className="map-refresh-button" onClick={props.refreshMap}> Refresh</button></div>
    </GoogleMap>
);

const SingleVehicleMap = compose(
    withProps({
        googleMapURL: googleMapURL,
        loadingElement: loadingElement,
        containerElement: containerElement,
        mapElement: mapElement,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const refs = {}
            const DirectionsService = new window.google.maps.DirectionsService();

            var start = new window.google.maps.LatLng(parseFloat(this.props.vehicleDetails[0].latitude), parseFloat(this.props.vehicleDetails[0].longitude));
            var end = "";
            if (this.props.vehicleDetails[0].rideStatusId == 108) {
                end = new window.google.maps.LatLng(parseFloat(this.props.vehicleDetails[0].doLatitude), parseFloat(this.props.vehicleDetails[0].doLongitude));
            } else {
                end = new window.google.maps.LatLng(parseFloat(this.props.vehicleDetails[0].puLatitude), parseFloat(this.props.vehicleDetails[0].puLongitude));
            }

            DirectionsService.route({
                origin: start,
                destination: end,
                travelMode: window.google.maps.TravelMode.DRIVING,
                durationInTraffic: true
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                        eta: result.routes[0].legs[0].duration_in_traffic.text
                    });

                    this.setState({
                        bounds: null,
                        showInfoIndex: 0,
                        onMapMounted: ref => {
                            refs.map = ref;
                            //console.log("Zoom to markers");
                            const bounds = new window.google.maps.LatLngBounds();
                            ref.props.children.forEach((child) => {
                                if (child.type === Marker) {
                                    bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                                }
                            })
                            ref.fitBounds(bounds);
                        },
                        onBoundsChanged: () => {
                            this.setState({
                                bounds: refs.map.getBounds(),
                                center: refs.map.getCenter(),
                            })
                        },
                        showInfo: (a) => {
                            this.setState({ showInfoIndex: a })
                        },
                        handleCloseCall: () => {
                            this.setState({ showInfoIndex: '' })
                        },
                        showAddressInfo: (a) => {
                            this.setState({ showAddessWindow: true })
                        },
                        handleCloseAddress: (a) => {
                            this.setState({ showAddessWindow: false })
                        },
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        }
    })
)(props =>
    <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={15}
        defaultOptions={{
            mapTypeControl: true,
            streetViewControl: true,
            zoomControl: true,
            fullscreenControl: false,
            minZoom: 2,
            maxZoom: 18,
            gestureHandling: "greedy"
        }}
        defaultCenter={{ lat: parseFloat(props.vehicleDetails[0].latitude), lng: parseFloat(props.vehicleDetails[0].longitude) }}
        onBoundsChanged={props.onBoundsChanged}>

        {props.vehicleDetails[0].rideStatusId === 108 &&
            <Marker
                position={{ lat: parseFloat(props.vehicleDetails[0].doLatitude), lng: parseFloat(props.vehicleDetails[0].doLongitude) }}
                onClick={() => { props.showAddressInfo() }}
            >
                {(props.showAddessWindow) &&
                    <InfoWindow onCloseClick={props.handleCloseAddress} options={{ maxWidth: 100 }} >
                        <div style={{ paddingBottom: 3 + 'px' }}>
                            <span>{props.vehicleDetails[0].doStreet}</span>
                        </div>
                    </InfoWindow>}
            </Marker>}

        {props.vehicleDetails[0].rideStatusId != 108 && <Marker
            position={{ lat: parseFloat(props.vehicleDetails[0].puLatitude), lng: parseFloat(props.vehicleDetails[0].puLongitude) }}
            onClick={() => { props.showAddressInfo() }}
        >
            {(props.showAddessWindow) &&
                <InfoWindow onCloseClick={props.handleCloseAddress} options={{ maxWidth: 100 }} >
                    <div style={{ paddingBottom: 3 + 'px' }}>
                        <span>{props.vehicleDetails[0].puStreet}</span>
                    </div>
                </InfoWindow>}
        </Marker>}

        {props.vehicleDetails.map((marker, index) =>
            <Marker
                key={index}
                position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                icon={{
                    url: "https://member.utwiz.com/TrackVehicle/Car/0.png",
                    anchor: new window.google.maps.Point(20, 20),
                    scaledSize: new window.google.maps.Size(30, 30)
                }}
                onClick={() => { props.showInfo(index) }}
            >
                {(props.showInfoIndex !== '') && (props.showInfoIndex == index) &&
                    <InfoWindow onCloseClick={props.handleCloseCall} options={{ maxWidth: 100 }} >
                        <div style={{ paddingBottom: 3 + 'px' }}>
                            <div style={{ paddingBottom: 8 + 'px' }}><strong className="text-muted">Ride Id: </strong>{marker.rideId}</div>
                            <table className="table table-head-fixed table-hover">
                                <tbody>
                                    <tr>
                                        <td><strong className="text-muted"><i className="fas fa-user"></i> Crew Name</strong><br /><label id="lblPaxName">{marker.paxName.replace(';', ',')}</label></td>
                                    </tr>
                                    <tr>
                                        <td width='60%'><strong className="text-muted"><i className="fas fa-car"></i> Vehicle</strong><br /><label id="lblVehicle">{marker.vehicleNo}</label></td>
                                        <td><strong className="text-muted"><i className="fas fa-user"></i> Driver</strong><br /><label id="lblDriver">{marker.driverName}</label> <label className="badge badge-success" id="lblStatus">{marker.statusDescription}</label></td>
                                    </tr>
                                    <tr>
                                        <td><strong className="text-muted"><i className="fas fa-clock"></i> Last GPS Time</strong><br /><label id="lblGpsTime">{marker.gpsDateTimeStr}</label></td>
                                        <td><strong className="text-muted"><i className="fas fa-clock"></i> Pickup Time</strong><br /><label id="lblPickupTime">{marker.pickupDateTimeStr}</label></td>

                                    </tr>
                                    <tr>
                                        <td align="top"><strong className="text-muted"><i className="fas fa-map-marker-alt"></i> Pick-up</strong><br /><label id="lblPickup">{marker.puStreet}</label></td>
                                        <td align="top"><strong className="text-muted"><i className="fas fa-map-marker-alt"></i> Dropoff</strong><br /><label id="lblDropoff">{marker.doStreet}</label></td>
                                    </tr>
                                    <tr>
                                        <td><strong className="text-muted"><i className="fas fa-hourglass-start"></i> <span id="lblEtaEtdName">{this?.props?.vehicleDetails[0]?.rideStatusId === 108 ? "ETD" : "ETA"}</span></strong><br /><span id="lblEtaEtd">{props.eta}</span></td>
                                        <td><strong className="text-muted"><i className="fas fa-tachometer-alt"></i> Speed</strong><br /><label id="lblSpeed">{marker.speed.toFixed(3)} MPH</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </InfoWindow>}
            </Marker>
        )}



        {props.directions && <DirectionsRenderer directions={props.directions} options={{
            polylineOptions: {
                strokeOpacity: 0.5,
                //strokeColor: '#FF0000',
            },
            suppressMarkers: true

        }} />}
        <div><button type="button" className="map-refresh-button" onClick={props.refreshMap}> Refresh</button></div>
    </GoogleMap>

);

const MultipleVehicleMap = compose(
    withProps({
        googleMapURL: googleMapURL,
        loadingElement: loadingElement,
        containerElement: containerElement,
        mapElement: mapElement,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({

        componentDidMount() {
            this.loadDetails(this.props.vehicleDetails)
        },

        loadDetails(data) {
            const refs = {}
            this.setState({
                rawData: data,
                bounds: null,
                showInfoIndex: '',
                isShowlist: false,
                onMapMounted: ref => {
                    refs.map = ref;
                    //console.log("Zoom to markers");
                    const bounds = new window.google.maps.LatLngBounds();
                    data.map((item, i) =>
                        bounds.extend(new window.google.maps.LatLng(item.latitude, item.longitude))
                    )

                    //ref.props.children.forEach((child) => {
                    //    if (child.type === Marker) {
                    //        bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                    //    }
                    //})
                    //let myVar = setTimeout(() => ref.fitBounds(bounds), 5000)

                    if (ref != null) {
                        ref.fitBounds(bounds);
                    }
                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    })
                },
                showInfo: (a) => {
                    this.setState({ showInfoIndex: a })
                },
                handleFocusVehicle: (a) => {

                    this.setState({ showInfoIndex: a, zoomLevel: 18, center: { lat: data[a].latitude, lng: data[a].longitude } })
                },
                handleCloseCall: () => {
                    this.setState({ showInfoIndex: '' })
                },
                handleToggleButton: () => {
                    this.setState({ isShowlist: this.state.isShowlist ? false : true })
                    this.setState({ showInfoIndex: '', zoomLevel: undefined })
                    const bounds = new window.google.maps.LatLngBounds();
                    data.map((item, i) =>
                        bounds.extend(new window.google.maps.LatLng(item.latitude, item.longitude))
                    )
                    refs.map.fitBounds(bounds);
                },
            });
        }
    })
)(props =>
    <div>
        <GoogleMap
            ref={props.onMapMounted}
            defaultOptions={{
                mapTypeControl: true,
                streetViewControl: true,
                zoomControl: true,
                fullscreenControl: false,
                minZoom: 2,
                maxZoom: 18,
                gestureHandling: "greedy"
            }}
            zoom={props.zoomLevel}
            center={props.center}
            onBoundsChanged={props.onBoundsChanged}>
            {props.rawData != null && props.rawData.map((marker, index) =>
                <Marker
                    key={index}
                    position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                    icon={{
                        url: "https://member.utwiz.com/TrackVehicle/Car/0.png",
                        anchor: new window.google.maps.Point(20, 20),
                        scaledSize: new window.google.maps.Size(30, 30)
                    }}
                    onClick={() => { props.showInfo(index) }}
                >
                    {(props.showInfoIndex !== '') && (props.showInfoIndex == index) &&
                        <InfoWindow onCloseClick={props.handleCloseCall} options={{ maxWidth: 100 }} >
                            <div style={{ paddingBottom: 3 + 'px' }}>
                                <div style={{ paddingBottom: 8 + 'px' }}><strong className="text-muted">Ride Id: </strong>{marker.rideId}</div>
                                <table className="info-window-table">
                                    <tbody>
                                        <tr>
                                            <td><strong className="text-muted"><i className="fas fa-user"></i> Crew Name</strong><br /><label id="lblPaxName">{marker.paxName.replace(';', ',')}</label></td>
                                        </tr>
                                        <tr>
                                            <td width='60%'><strong className="text-muted"><i className="fas fa-car"></i> Vehicle</strong><br /><label id="lblVehicle">{marker.vehicleNo}</label></td>
                                            <td><strong className="text-muted"><i className="fas fa-user"></i> Driver</strong><br /><label id="lblDriver">{marker.driverName}</label> <label className="badge badge-success" id="lblStatus">{marker.statusDescription}</label></td>
                                        </tr>
                                        <tr>
                                            <td><strong className="text-muted"><i className="fas fa-clock"></i> Last GPS Time</strong><br /><label id="lblGpsTime">{marker.gpsDateTimeStr}</label></td>
                                            <td><strong className="text-muted"><i className="fas fa-clock"></i> Pickup Time</strong><br /><label id="lblPickupTime">{marker.pickupDateTimeStr}</label></td>

                                        </tr>
                                        <tr>
                                            <td align="top"><strong className="text-muted"><i className="fas fa-map-marker-alt"></i> Pick-up</strong><br /><label id="lblPickup">{marker.puStreet}</label></td>
                                            <td align="top"><strong className="text-muted"><i className="fas fa-map-marker-alt"></i> Dropoff</strong><br /><label id="lblDropoff">{marker.doStreet}</label></td>
                                        </tr>
                                        <tr>
                                            <td><strong className="text-muted"><i className="fas fa-user"></i> <span id="lblEtaEtdName">Pax</span></strong><br /><span id="lblEtaEtd">{marker.pax}</span></td>
                                            <td><strong className="text-muted"><i className="fas fa-tachometer-alt"></i> Speed</strong><br /><label id="lblSpeed">{marker.speed.toFixed(3)} MPH</label></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </InfoWindow>}
                </Marker>
            )}
            <div><button type="button" className="map-refresh-button" onClick={props.refreshMap}> Refresh</button></div>
        </GoogleMap>
        <div id="map-body">
            <div id="map-list-data" className="card" style={{ display: props.isShowlist ? "block" : "none" }}>
                <div className="card-body table-responsive p-0" style={{ height: 250 + 'px' }}>
                    <table className="table table-head-fixed table-hover">
                        <thead>
                            <tr>
                                <th width="10px"></th>
                                <th>Vehicle Number</th>
                                <th>Vehicle Type</th>
                                <th>Total Pax</th>
                                <th>Driver Name</th>
                                <th>Pickup Time</th>
                                <th>Last GPS Time</th>
                                <th>Trip Status</th>
                                <th>Vehicle Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.rawData != null && props.rawData.map((item, j) =>
                                <tr className="callout callout-success" key={j}>
                                    <td><a style={{ cursor: "pointer" }} onClick={() => { props.handleFocusVehicle(j) }}><i className="fas fa-car text-info"></i></a></td>
                                    <td>{item.vehicleNo}</td>
                                    <td>{item.vehicleType}</td>
                                    <td>{item.pax}</td>
                                    <td>{item.driverName}</td>
                                    <td>{item.pickupDateTimeStr}</td>
                                    <td>{item.gpsDateTimeStr}</td>
                                    <td>{item.gpsstatus}</td>
                                    <td><span className="badge bg-success">{item.statusDescription}</span></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div id="bottom_bar">
            <span id="bottom_menu">
                <a className="btn btn-success btn-sm btn-block" onClick={props.handleToggleButton}>Click here to show/hide list of vehicles</a>
            </span>
        </div>
    </div>
);

